type GradientParams = [number, string, string]

function generateGradient(...[angle, start, end]: GradientParams) {
  return `linear-gradient(${angle}deg, ${start}, ${end})`
}

type ColorProps = GradientParams | string

export function createColor(
  properties: ColorProps,
  defaultValue?: string
): string
export function createColor(
  properties: ColorProps | undefined,
  defaultValue: string
): string
export function createColor(
  properties: ColorProps | undefined,
  defaultValue?: string
): string | undefined

export function createColor(
  properties: ColorProps | undefined,
  defaultValue?: string
): string | undefined {
  if (Array.isArray(properties)) {
    return generateGradient(...properties)
  }

  return properties || defaultValue
}

type GradientTextColorStyle = {
  background: string
  '-webkit-text-fill-color': string
  '-webkit-background-clip': string
}

export function createTextColorStyle(
  properties: GradientParams,
  defaultValue?: string
): GradientTextColorStyle
export function createTextColorStyle(
  properties: string,
  defaultValue?: string
): { color: string }
export function createTextColorStyle(
  properties: ColorProps,
  defaultValue?: string
): GradientTextColorStyle | { color: string }
export function createTextColorStyle(
  properties: string | undefined,
  defaultValue: string
): { color: string }
export function createTextColorStyle(
  properties: ColorProps | undefined,
  defaultValue?: string
): GradientTextColorStyle | { color: string | undefined }

export function createTextColorStyle(
  properties: ColorProps | undefined,
  defaultValue?: string
): GradientTextColorStyle | { color: string | undefined } {
  if (Array.isArray(properties)) {
    return {
      background:
        '-webkit-' +
        generateGradient(properties[0] + 180, properties[1], properties[2]),
      '-webkit-text-fill-color': 'transparent',
      '-webkit-background-clip': 'text'
    }
  }

  return {
    color: properties || defaultValue
  }
}

export const toRGBString = (r: number, g: number, b: number, a = 1) =>
  `rgba(${r}, ${g}, ${b}, ${a})`

export const RGBComponentsToLuma = (r: number, g: number, b: number, a = 1) =>
  Math.round(a * (0.2126 * r + 0.7152 * g + 0.0722 * b))
